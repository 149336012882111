import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
export default defineComponent({
  props: {
    assessments: {
      type: Array,
      required: true
    },
    impacts: {
      type: Array,
      required: true
    },
    stage: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      type: 'impactpotential'
    };
  },
  methods: {
    getImpacts: function getImpacts(assessment) {
      if (this.impacts === undefined) return [];
      return this.impacts.filter(function (impact) {
        return impact.assessment.includes(assessment.id);
      });
    },
    get score() {
      if (['Outcome-selection venture', 'Outcome-selection reviewer'].includes(this.stage)) return 'impactPotentialPhase1';
      return 'impactPotentialScore';
    },
    formatScore: function formatScore(score) {
      if (score == null) {
        return '—&nbsp;';
      }
      if (this.type === 'confidence') {
        return score.toFixed(1);
      } else {
        return (score * 100).toFixed(0);
      }
    }
  }
});