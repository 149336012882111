import { render, staticRenderFns } from "./VenturesOverview.vue?vue&type=template&id=51f8e228"
import script from "./VenturesOverview.vue?vue&type=script&lang=ts"
export * from "./VenturesOverview.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TotalMaterialImpacts: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/TotalMaterialImpacts.vue').default,Term: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/Term.vue').default,ImpactPillOutcomeScore: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactPillOutcomeScore.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VChip,VCol,VLayout,VRow})
